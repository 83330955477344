var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper -esparragos padding-menu -fondo-campo"},[_c('div',{staticClass:"breadcrumb"},[_c('div',{staticClass:"container row-end"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Grupo IAN")]),_c('router-link',{attrs:{"to":'/' + _vm.$t('paths.productos')}},[_vm._v(_vm._s(_vm.$t("navbar_menu.products.title")))]),_c('router-link',{attrs:{"to":'/' + _vm.$t('paths.ficha-categoria-producto') + '/' + _vm.id}},[_vm._v(" "+_vm._s(_vm.$t(`categorias_productos["${_vm.id}"]`).name)+" ")]),_c('router-link',{attrs:{"to":`/${_vm.$t('paths.ficha-producto')}/${_vm.id}/${_vm.product}`}},[_vm._v(_vm._s(_vm.$t(`categorias_productos["${_vm.id}"]`)["productos"][`${_vm.product}`].name))])],1)]),_c('section',{staticClass:"intro-page"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t(`categorias_productos["${_vm.id}"]`)["productos"][`${_vm.product}`].name)+" "),_c('router-link',{attrs:{"to":{
            name: 'ficha-categoria-producto',
            params: {
              id: _vm.id,
            },
          }}},[_vm._v(" > ("+_vm._s(_vm.$t(`categorias_productos["${_vm.id}"]`)["productos"][`${_vm.product}`] .categoria)+") ")])],1),_c('img',{attrs:{"src":`/img/productos/${_vm.$t(`categorias_productos['${_vm.id}']`).id}/${
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`].id
          }/intro.jpg`,"alt":_vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`].name}}),_c('div',{staticClass:"green-box"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t(`categorias_productos["${_vm.id}"]`)["productos"][`${_vm.product}`] .title)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t(`categorias_productos["${_vm.id}"]`)["productos"][`${_vm.product}`] .subtitle)+" ")])])])]),_c('section',{staticClass:"cuerpo-producto"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"texto"},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
              .descripcion
          )}}),(
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
              .cta_link &&
              _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
                .link
          )?_c('a',{staticClass:"arrow-link",attrs:{"href":_vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
              .link,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t(`categorias_productos['${_vm.id}']`)["productos"][`${_vm.product}`] .cta_link))]):_vm._e()]),_c('img',{staticClass:"producto-1",attrs:{"src":`/img/productos/${_vm.$t(`categorias_productos['${_vm.id}']`).id}/${
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`].id
          }/producto-1.jpg`,"alt":_vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`].name}}),_c('img',{staticClass:"producto-2",attrs:{"src":`/img/productos/${_vm.$t(`categorias_productos['${_vm.id}']`).id}/${
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`].id
          }/producto-2.jpg`,"alt":_vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`].name}})])]),(
      _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
        .extra_descripcion
    )?_c('section',{staticClass:"cuerpo-producto -middle"},[_c('div',{staticClass:"container"},[_c('img',{staticClass:"producto-3",attrs:{"src":`/img/productos/${_vm.id}/${_vm.product}/producto-3.jpg`,"alt":_vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`].name}}),_c('div',{staticClass:"texto"},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
              .extra_descripcion
          )}}),(
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
              .extra_cta_link &&
              _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
                .extra_link
          )?_c('a',{staticClass:"arrow-link",attrs:{"href":_vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
              .extra_link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t(`categorias_productos['${_vm.id}']`)["productos"][`${_vm.product}`] .extra_cta_link)+" ")]):_vm._e()])])]):_vm._e(),_c('section',{staticClass:"gama-formatos"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v(_vm._s(_vm.$t("gamas_y_formatos")))]),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
            .texto_gama_formatos
        )}}),_c('img',{attrs:{"src":`/img/productos/${_vm.$t(`categorias_productos['${_vm.id}']`).id}/${
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`].id
          }/img-gama.jpg`,"alt":`Gama y formatos ` +
            _vm.$t(`categorias_productos['${_vm.id}']`)['productos'][`${_vm.product}`]
              .name}})])]),_c('section',{staticClass:"formulario-container"},[_c('div',{staticClass:"container row-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t("nos_adaptamos")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('contact.form.text'))}}),_c('formulario')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }