<template>
  <div class="wrapper -esparragos padding-menu -fondo-campo">
    <div class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link :to="'/' + $t('paths.productos')">{{
          $t("navbar_menu.products.title")
        }}</router-link>
        <router-link
          :to="'/' + $t('paths.ficha-categoria-producto') + '/' + id"
        >
          {{ $t(`categorias_productos["${id}"]`).name }}
        </router-link>
        <router-link :to="`/${$t('paths.ficha-producto')}/${id}/${product}`">{{
          $t(`categorias_productos["${id}"]`)["productos"][`${product}`].name
        }}</router-link>
      </div>
    </div>

    <section class="intro-page ">
      <div class="container">
        <h1>
          {{
            $t(`categorias_productos["${id}"]`)["productos"][`${product}`].name
          }}
          <router-link
            :to="{
              name: 'ficha-categoria-producto',
              params: {
                id: id,
              },
            }"
          >
            > ({{
              $t(`categorias_productos["${id}"]`)["productos"][`${product}`]
                .categoria
            }})
          </router-link>
        </h1>
        <img
          :src="
            `/img/productos/${$t(`categorias_productos['${id}']`).id}/${
              $t(`categorias_productos['${id}']`)['productos'][`${product}`].id
            }/intro.jpg`
          "
          :alt="
            $t(`categorias_productos['${id}']`)['productos'][`${product}`].name
          "
        />
        <div class="green-box">
          <h2>
            {{
              $t(`categorias_productos["${id}"]`)["productos"][`${product}`]
                .title
            }}
          </h2>
          <p>
            {{
              $t(`categorias_productos["${id}"]`)["productos"][`${product}`]
                .subtitle
            }}
          </p>
        </div>
      </div>
    </section>

    <section class="cuerpo-producto">
      <div class="container">
        <div class="texto">
          <div
            v-html="
              $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                .descripcion
            "
          ></div>
          <a
            v-if="
              $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                .cta_link &&
                $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                  .link
            "
            :href="
              $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                .link
            "
            target="_blank"
            class="arrow-link"
            >{{
              $t(`categorias_productos['${id}']`)["productos"][`${product}`]
                .cta_link
            }}</a
          >
        </div>
        <img
          :src="
            `/img/productos/${$t(`categorias_productos['${id}']`).id}/${
              $t(`categorias_productos['${id}']`)['productos'][`${product}`].id
            }/producto-1.jpg`
          "
          :alt="
            $t(`categorias_productos['${id}']`)['productos'][`${product}`].name
          "
          class="producto-1"
        />
        <img
          :src="
            `/img/productos/${$t(`categorias_productos['${id}']`).id}/${
              $t(`categorias_productos['${id}']`)['productos'][`${product}`].id
            }/producto-2.jpg`
          "
          :alt="
            $t(`categorias_productos['${id}']`)['productos'][`${product}`].name
          "
          class="producto-2"
        />
      </div>
    </section>

    <section
      class="cuerpo-producto -middle"
      v-if="
        $t(`categorias_productos['${id}']`)['productos'][`${product}`]
          .extra_descripcion
      "
    >
      <div class="container">
        <img
          :src="`/img/productos/${id}/${product}/producto-3.jpg`"
          :alt="
            $t(`categorias_productos['${id}']`)['productos'][`${product}`].name
          "
          class="producto-3"
        />
        <div class="texto">
          <div
            v-html="
              $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                .extra_descripcion
            "
          ></div>
          <a
            v-if="
              $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                .extra_cta_link &&
                $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                  .extra_link
            "
            :href="
              $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                .extra_link
            "
            target="_blank"
            class="arrow-link"
          >
            {{
              $t(`categorias_productos['${id}']`)["productos"][`${product}`]
                .extra_cta_link
            }}
          </a>
        </div>
      </div>
    </section>

    <section class="gama-formatos">
      <div class="container">
        <h2>{{ $t("gamas_y_formatos") }}</h2>
        <div
          v-html="
            $t(`categorias_productos['${id}']`)['productos'][`${product}`]
              .texto_gama_formatos
          "
        ></div>
        <img
          :src="
            `/img/productos/${$t(`categorias_productos['${id}']`).id}/${
              $t(`categorias_productos['${id}']`)['productos'][`${product}`].id
            }/img-gama.jpg`
          "
          :alt="
            `Gama y formatos ` +
              $t(`categorias_productos['${id}']`)['productos'][`${product}`]
                .name
          "
        />
      </div>
    </section>

    <section class="formulario-container">
      <div class="container row-center">
        <h2>{{ $t("nos_adaptamos") }}</h2>
        <div v-html="$t('contact.form.text')"></div>
        <formulario></formulario>
      </div>
    </section>
  </div>
</template>

<script>
import formulario from "@/components/formulario-contacto";

export default {
  name: "ficha-producto",
  props: ["id", "product"],
  components: {
    formulario: formulario,
  },
  mounted() {
    document.title = this.$t(
      `categorias_productos.${this.$route.params.id}.productos.${this.$route.params.product}`
    ).seo.title;
    document.head.querySelector("[name=title]").content = this.$t(
      `categorias_productos.${this.$route.params.id}.productos.${this.$route.params.product}`
    ).seo.title;
    document.head.querySelector("[name=description]").content = this.$t(
      `categorias_productos.${this.$route.params.id}.productos.${this.$route.params.product}`
    ).seo.description;
  },
};
</script>
